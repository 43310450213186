import { Component, Input } from '@angular/core';

@Component({
  selector: 'smw-tooltip',
  standalone: true,
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.less'],
})
export class TooltipComponent {
  @Input() text: string = '';
  @Input() position: 'top' = 'top' as const; // Others direction not yet implemented
  @Input() show: boolean = false;
  @Input() disabled: boolean = false;
  @Input() color: string = '#0E153F';
}
