import {
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  input,
  OnDestroy,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { Overlay } from './overlay';

/**
 * MenuTrigger takes care of the creation of the menu and to add it into the DOM.
 * The directive will handle the position of the target element based on the provided positions.
 * If no positions are provided, the element is positioned to be always visible in the viewport.
 */
@Directive({ selector: '[menuTriggerFor]', standalone: true })
export class MenuTriggerDirective implements OnDestroy {
  menu = input.required<TemplateRef<void>>({ alias: 'menuTriggerFor' });
  xPosition = input<'left-inner' | 'left-outer' | 'right-inner' | 'right-outer'>();
  yPosition = input<'top-inner' | 'top-outer' | 'bottom-inner' | 'bottom-outer'>();

  private viewRef?: EmbeddedViewRef<void>;
  private menuElement?: HTMLElement;
  private resizeObserver?: ResizeObserver;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private vcr: ViewContainerRef,
    private renderer: Renderer2,
    private overlay: Overlay,
  ) {}

  ngOnDestroy(): void {
    this.destroyResizeObserver();
  }

  @HostListener('click')
  onClick() {
    const menu = this.menu();
    if (!menu) {
      return;
    }

    if (!this.viewRef || this.viewRef.destroyed) {
      this.viewRef = this.vcr.createEmbeddedView(menu);

      const overlay = this.overlay.create();

      this.overlay.attach(this.viewRef);

      for (const node of this.viewRef.rootNodes) {
        this.renderer.appendChild(overlay, node);
      }

      this.menuElement = this.viewRef.rootNodes[0];

      if (this.menuElement) {
        this.setElementPosition(this.menuElement);
        this.observeResizeOf(this.menuElement);
      }
    }
  }

  @HostListener('document:scroll')
  onScroll() {
    if (this.menuElement) {
      this.setElementPosition(this.menuElement);
    }
  }

  /**
   * A menu can be displayed :
   *  1. below the trigger (default)
   *  2. above the trigger if there is no room to open down
   */
  private setElementPosition(element: HTMLElement): void {
    const trigger = this.elementRef.nativeElement;
    const triggerRect = trigger.getBoundingClientRect();

    this.renderer.setStyle(element, 'position', 'absolute');
    this.positionHorizontally(element, triggerRect);
    this.positionVertically(element, triggerRect);
  }

  /**
   * The horizontal position of the element will be based on xPosition.
   * If not provided, the element will have a bottom position if enough place or top otherwise
   */
  private positionVertically(element: HTMLElement, triggerRect: DOMRect): void {
    const elementHeight = element.offsetHeight;
    const viewportHeight = document.documentElement.offsetHeight;

    const position = this.yPosition();
    if (!position) {
      const enougthPlaceOnBottom = viewportHeight - triggerRect.bottom > elementHeight;
      if (enougthPlaceOnBottom) {
        this.positionBottom(element, triggerRect);
      } else {
        this.renderer.setStyle(element, 'top', `${triggerRect.top - element.offsetHeight}px`);
      }

      return;
    }

    switch (position) {
      case 'top-inner':
        this.renderer.setStyle(element, 'top', `${triggerRect.top}px`);
        break;
      case 'top-outer':
        this.renderer.setStyle(element, 'top', `${triggerRect.top - element.offsetHeight}px`);
        break;
      case 'bottom-inner':
        this.renderer.setStyle(element, 'top', `${triggerRect.bottom - element.offsetHeight}px`);
        break;
      case 'bottom-outer':
        this.renderer.setStyle(element, 'top', `${triggerRect.bottom}px`);
        break;
      default:
        break;
    }
  }

  private positionBottom(element: HTMLElement, triggerRect: DOMRect): void {
    this.renderer.setStyle(element, 'top', `${triggerRect.bottom}px`);
  }

  /**
   * The horizontal position of the element will be based on xPosition.
   * If not provided, the element will have a left-inner position
   */
  private positionHorizontally(element: HTMLElement, triggerRect: DOMRect): void {
    const position = this.xPosition();

    switch (position) {
      case 'left-inner':
        this.renderer.setStyle(element, 'left', `${triggerRect.left}px`);
        break;
      case 'left-outer':
        this.renderer.setStyle(element, 'left', `${triggerRect.left - element.offsetWidth}px`);
        break;
      case 'right-inner':
        this.renderer.setStyle(element, 'left', `${triggerRect.right - element.offsetWidth}px`);
        break;
      case 'right-outer':
        this.renderer.setStyle(element, 'left', `${triggerRect.right}px`);
        break;
      default:
        this.renderer.setStyle(element, 'left', `${triggerRect.left}px`);
        break;
    }
  }

  /**
   * When adding to the viewport, the menu may not have its final height, for example when the elements are loaded asynchronously.
   * In this case, the position may be off.
   * This method allows to check if there is a resize of the menu.
   * We only have to check once for the initial population of the menu, thus we unobserve as soon as the reposition is done.
   */
  private observeResizeOf(element: HTMLElement) {
    this.resizeObserver = new ResizeObserver(() => {
      this.setElementPosition(element);
      this.destroyResizeObserver();
    });

    this.resizeObserver.observe(element);
  }

  private destroyResizeObserver(): void {
    this.resizeObserver?.disconnect();
  }
}
