import { HttpClient } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import { Observable, share, take, tap } from 'rxjs';

import { User as ApiUser, ClientPlugins, UserModuleAccess, UserRole } from '@so-many-ways/models';

type Company = {
  id: string;
  name: string;
  enabledModules: ClientPlugins[];
  mobilityRegistrationLink: string;
};

export type User = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  roles: UserRole[];
  company: Company;
  grantedModules: UserModuleAccess[];
};

@Injectable({ providedIn: 'root' })
export class UserStore {
  constructor(private http: HttpClient) {
    this.loadUser().subscribe();
  }

  user = signal<User | undefined>(undefined);

  company = computed(() => this.user()?.company);

  firstname = computed(() => {
    const user = this.user();
    if (!user) {
      return '';
    }

    return user.firstname;
  });
  lastname = computed(() => {
    const user = this.user();
    if (!user) {
      return '';
    }

    return user.lastname;
  });
  fullname = computed(() => `${this.firstname()} ${this.lastname()}`.trim());

  roles = computed(() => this.user()?.roles ?? []);

  isCollaborator = computed(() => {
    const user = this.user();
    if (!user) {
      return false;
    }

    return user.roles.includes('collaborator');
  });

  isManager = computed(() => {
    const user = this.user();
    if (!user) {
      return false;
    }

    return user.roles.includes('manager');
  });

  isHR = computed(() => {
    const user = this.user();
    if (!user) {
      return false;
    }

    return user.roles.includes('hr');
  });

  companyEnablesModules = computed(() => {
    const company = this.company();
    if (!company) {
      return [];
    }

    return company.enabledModules;
  });
  grantedModules = computed(() => {
    const user = this.user();
    if (!user) {
      return [];
    }

    return user.grantedModules;
  });

  loadUser(): Observable<ApiUser> {
    return this.http.get<ApiUser>('/api/user').pipe(
      take(1),
      tap((user) => {
        if (user) {
          this.user.set({
            id: user.id,
            firstname: user.firstname,
            lastname: user.lastname ?? '',
            roles: user.roles,
            email: user.email,
            company: {
              id: user.client!.id,
              name: user.client!.name,
              enabledModules: user.client!.plugins,
              mobilityRegistrationLink: user.client!.mobilityRegistration ?? '',
            },
            grantedModules: user.moduleAccess,
          });
        }
      }),
      share(),
    );
  }

  clear(): void {
    this.user.set(undefined);
  }
}
