import { Pipe, PipeTransform } from '@angular/core';

import { ExtractParams, ExtractPaths, ExtractRoute, NavService } from './nav.service';
import { APP_ROUTES } from './routes';

@Pipe({
  name: 'route',
  standalone: true,
})
export class RoutePipe implements PipeTransform {
  constructor(private navigator: NavService) {}
  transform(
    path: ExtractPaths<typeof APP_ROUTES>,
    /** I got types errors from conversation.page.html - Don't know how to fix it. That's why I added an 'any'
     * 
      [routerLink]="[
          viewingMode() === 'manager'
            ? ('manager.conversations.conversation.resource'
              | route: { conversationId: conversation.id, step: 'resource_best_practices' })
            : ('collaborator.conversations.conversation.resource'
              | route: { conversationId: conversation.id, step: 'resource_best_practices' })
        ]" 
    
    */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params?: ExtractParams<ExtractRoute<ExtractPaths<typeof APP_ROUTES>, typeof APP_ROUTES>> | any,
  ): string {
    return this.navigator.routeOf(path, params) ?? '';
  }
}
