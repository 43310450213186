<smw-background />

<main>
  <h1 class="title-lg">{{ title() }}</h1>

  @if (message()) {
    <p class="body-lg">{{ message() }}</p>
  }

  <button smw-button kind="filled" class="confirm" (click)="confirm.emit()">{{ cta() }}</button>
</main>

<smw-rain-w />
