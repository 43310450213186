export const ClientPlugins = {
  workoscope: 'workoscope',
  interview: 'interview',
  b2c: 'b2c',
  synthese: 'synthese',
  mobility: 'mobility',
  ai: 'ai',
  formations: 'formations',
} as const;

export type ClientPlugins = (typeof ClientPlugins)[keyof typeof ClientPlugins];
