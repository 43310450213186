import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  input,
  Renderer2,
  signal,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isEqual } from 'lodash';

import { OutsideClickDirective } from '@smw/front-utils';

import { IconComponent } from '../../icon/icon.component';

export type SelectOption<T> = {
  value: T;
  label: string;
  disabled?: boolean;
};

@Component({
  selector: 'smw-select',
  standalone: true,
  imports: [CommonModule, OutsideClickDirective, IconComponent, HttpClientModule],
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectComponent,
      multi: true,
    },
  ],
})
export class SelectComponent<T = unknown> implements ControlValueAccessor {
  label = input('');
  placeholder = input('');
  options = input<SelectOption<T>[]>([]);
  valueKey = input<keyof T | undefined>(undefined);
  invalid = input(false);

  @ViewChild('selectContainer', { static: true, read: ElementRef })
  selectField!: ElementRef<HTMLInputElement>;

  @ContentChild('optionTemplate', { read: TemplateRef })
  optionTemplate!: TemplateRef<unknown>;

  inputId = signal('');
  selectedOption = signal<SelectOption<T> | undefined>(undefined);
  isMenuDisplayed = signal(false);
  isDisabled = signal(false);

  onChange!: (value: T | T[keyof T]) => void;
  onTouched!: () => void;
  onValidationChange!: () => void;

  constructor(
    @Attribute('id') public id: string,
    private element: ElementRef,
    private renderer: Renderer2,
  ) {
    if (this.id) {
      this.inputId.set(this.id);
      this.renderer.removeAttribute(this.element.nativeElement, 'id');
    }
  }

  writeValue(value: T | T[keyof T] | null): void {
    const options = this.options();

    if (!options.length) {
      return;
    }

    if (value === undefined) {
      this.selectedOption.set(undefined);
      return;
    }

    if (value === null) {
      this.selectedOption.set(options.find((item) => item.value === null));
      return;
    }

    const valueKey = this.valueKey();
    if (valueKey) {
      this.selectedOption.set(options.find((item) => item.value[valueKey] === value));
    } else {
      this.selectedOption.set(options.find((item) => isEqual(item.value, value)));
    }

    if (!this.selectedOption()) {
      this.selectedOption.set({ label: '', value: value as T });
    }
  }

  registerOnChange(fn: (value: T | T[keyof T]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }

  select(option: SelectOption<T>): void {
    if (option.disabled) {
      return;
    }

    this.onTouched();
    this.selectedOption.set(option);

    const valueKey = this.valueKey();

    this.onChange(valueKey ? option.value[valueKey] : option.value);
    this.close();
  }

  close(): void {
    if (this.isMenuDisplayed()) {
      this.isMenuDisplayed.set(false);
    }
  }
}
