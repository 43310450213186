import { ActivatedRoute, Event, NavigationEnd } from '@angular/router';

export function isNavigationEnd(event: Event): event is NavigationEnd {
  return event instanceof NavigationEnd;
}

export function extractDataFromRouteTree(route: ActivatedRoute): Record<string, unknown> {
  return route.children.reduce((data, current) => {
    return { ...data, ...route.snapshot.data, ...extractDataFromRouteTree(current) };
  }, {});
}
