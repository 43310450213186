import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

@Component({
  selector: 'smw-icon',
  template: ``,
  styleUrls: ['./icon.component.less'],
  imports: [CommonModule],
  standalone: true,
})
export class IconComponent implements OnChanges {
  @Input() icon!: string;

  private get path(): string {
    return `assets/icons/${this.icon}.svg`;
  }

  constructor(
    private http: HttpClient,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnChanges(): void {
    this.http
      .get(this.path, { responseType: 'text', headers: { cacheable: 'true' } })
      .subscribe((svg) => {
        this.applySVG(svg);
      });
  }

  private applySVG(element: string | SVGElement): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', element);
  }
}
