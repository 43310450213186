import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { fr_FR, NzI18nService } from 'ng-zorro-antd/i18n';
import { filter, pairwise, Subject } from 'rxjs';

import { ANALYTICS, Analytics, EventIdentifier } from '@smw/front-analytics';
import { isNavigationEnd } from '@smw/front-navigation';
import { SpaceStore, UserStore } from '@smw/front-stores';
import { NotificationService } from '@smw/front-ui';

@Component({
  selector: 'smw-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(ANALYTICS) private analytics: Analytics,
    @Inject(LOCALE_ID) locale: string,
    @Inject(DOCUMENT) document: Document,
    private nzI18n: NzI18nService,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private hotkeysService: HotkeysService,
    private notification: NotificationService,
    private userStore: UserStore,
    private spaceStore: SpaceStore,
  ) {
    document.documentElement.lang = locale;
  }
  identifyUser = effect(() => {
    const user = this.userStore.user();
    if (!user) {
      return;
    }

    this.analytics.identify(user.id, {
      email: user.email,
    });

    Sentry.setUser({ username: user.firstname, email: user.email, id: user.id });

    // We could register a real analytics service for userback but since we only need the idenfity its a bit overkill
    // Also there's a typescript SDK, but similarly it's a bit overkill and make it rely on the app initialisation, while pure html integration make the feedback tool usable even if the app can't bootstrap // there's a code issue on in our stack.
    // Also, we will likely stop using userback very soon
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const userBack = (<any>window).Userback;
    userBack?.identify?.(user.id, {
      name: this.userStore.fullname(),
      email: user.email,
      account_id: user.company.id,
    });
  });

  private unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.setupI18n();
    this.trackNavigationEvents();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setupI18n(): void {
    this.nzI18n.setLocale(fr_FR);
    this.translateService.addLangs(['fr', 'key']);

    this.hotkeysService.add(
      new Hotkey('ctrl+alt+l', (): boolean => {
        if (this.translateService.currentLang === 'key') {
          this.translateService.use('fr');
        } else {
          this.notification.info({
            message: `Appuyez sur Ctrl + Alt + L pour afficher les textes.`,
          });
          this.translateService.use('key');
        }

        return false; // Prevent bubbling
      }),
    );
    this.changeDetectorRef.markForCheck();
  }

  private trackNavigationEvents(): void {
    this.router.events
      .pipe(filter(isNavigationEnd), pairwise())
      .subscribe((event: NavigationEnd[]) => {
        const previousNavigation = event[0];
        const currentNavigation = event[1];

        if (previousNavigation) {
          this.analytics.track({
            id: EventIdentifier.PageClosed,
            data: { url: previousNavigation.url },
          });
        }
        this.analytics.startTimer(EventIdentifier.PageClosed);

        this.analytics.track({
          id: EventIdentifier.PageOpened,
          data: {
            url: currentNavigation.url,
            previousUrl: previousNavigation.url,
          },
        });
      });
  }
}
