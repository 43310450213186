@if (shouldDisplaySidebar()) {
  <smw-sidebar
    [@slideInLeft]
    [@.disabled]="!smallDevice()"
    [smallDevice]="smallDevice()"
    [mediumDevice]="mediumDevice()"
    [largeDevice]="largeDevice()"
    (close)="closeSidebar()"
  />
}

<div class="wrapper">
  <smw-header
    [onRoot]="isSidebarEnabled()"
    [smallDevice]="smallDevice()"
    [mediumDevice]="mediumDevice()"
    [largeDevice]="largeDevice()"
    (openSidebar)="openSidebar()"
  />
  <div class="app-content">
    <router-outlet></router-outlet>
  </div>

  @if (smallDevice()) {
    <smw-footer [onRoot]="isSidebarEnabled()" />
  }
</div>
