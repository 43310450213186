<label [htmlFor]="inputId" class="caption-sm">{{ label }}</label>
<input
  #input
  [type]="supportTime() ? 'datetime-local' : 'date'"
  [id]="inputId"
  class="body-md"
  [value]="value"
  [min]="min"
  [max]="max"
  (input)="onDateChanged($event)"
/>
