@if (icon()) {
  <smw-icon [icon]="icon()"></smw-icon>
}

@if (!isIconButton()) {
  @if (loading()) {
    <smw-icon
      [icon]="invertLoading() ? 'loading-invert' : 'loading'"
      class="!w-14 absolute"
    ></smw-icon>
  }
  <div [ngClass]="loading() ? 'opacity-0' : ''">
    <ng-content></ng-content>
  </div>
}
