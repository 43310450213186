import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { of, tap } from 'rxjs';

import { IconCache } from './icon-cache.service';

export const iconCacheInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const cache = inject(IconCache);

  if (req.headers.get('cacheable')) {
    const cachedResponse = cache.get(req.urlWithParams);
    if (cachedResponse) {
      return of(cachedResponse);
    }

    return next(req).pipe(
      tap((httpEvent: HttpEvent<unknown>) => {
        if (httpEvent instanceof HttpResponse) {
          cache.put(req.urlWithParams, httpEvent);
        }
      }),
    );
  }
  return next(req);
};
