import { fromEvent, Observable, startWith } from 'rxjs';

export type Breakpoint = 'mobile' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export const BREAKPOINTS: Record<Breakpoint, { min: string; max: string; range: string }> = {
  mobile: {
    min: '(min-width: 0px)',
    max: '(max-width: 639.98px)',
    range: '(min-width: 0px) and (max-width: 639.98px)',
  },
  sm: {
    min: '(min-width: 640px)',
    max: '(max-width: 767.98px)',
    range: '(min-width: 640px) and (max-width: 767.98px)',
  },
  md: {
    min: '(min-width: 768px)',
    max: '(max-width: 1023.98px)',
    range: '(min-width: 768px) and (max-width: 1023.98px)',
  },
  lg: {
    min: '(min-width: 1024px)',
    max: '(max-width:  1279.98px)',
    range: '(min-width: 1024px) and (max-width: 1279.98px)',
  },
  xl: {
    min: '(min-width: 1280px)',
    max: '(max-width: 1535.98)',
    range: '(min-width: 1280px) and (max-width: 1535.98px)',
  },
  xxl: {
    min: '(min-width: 1536px)',
    max: '',
    range: '(min-width: 1536px)',
  },
};

export function toMediaQuery(breakpoints: Breakpoint[], exactBreakpoint = false): string {
  if (breakpoints.length > 1) {
    return `${BREAKPOINTS[breakpoints[0]].min} and ${BREAKPOINTS[breakpoints[1]].max}`;
  }

  if (exactBreakpoint) {
    return BREAKPOINTS[breakpoints[0]].range;
  }

  return BREAKPOINTS[breakpoints[0]].min;
}

export function fromBreakpointChange(
  breakpoint: Breakpoint,
): Observable<MediaQueryListEvent | undefined> {
  return fromEvent<MediaQueryListEvent>(
    matchMedia(toMediaQuery([breakpoint], true)),
    'change',
  ).pipe(startWith(undefined));
}
