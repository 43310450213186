/* eslint-disable @angular-eslint/directive-selector */
import { DomPortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Directive,
  Input,
  OnDestroy,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { combineLatest, debounceTime, Subject, takeUntil } from 'rxjs';

import { fromBreakpointChange } from '../helpers';

@Directive({
  selector: '[portal]',
  standalone: true,
})
export class PortalDirective implements AfterViewInit, OnDestroy {
  @Input('portal') hostSelector!: string;

  private host?: DomPortalOutlet;
  private unsubscribe$ = new Subject<void>();
  private currentHostElement?: Element | null;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private vcr: ViewContainerRef,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    combineLatest([
      fromBreakpointChange('mobile'),
      fromBreakpointChange('sm'),
      fromBreakpointChange('md'),
      fromBreakpointChange('lg'),
      fromBreakpointChange('xl'),
      fromBreakpointChange('xxl'),
    ])
      .pipe(debounceTime(50), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        const hostElement = this.getHostElement();

        if (hostElement) {
          if (!this.currentHostElement) {
            this.createPortal(hostElement);
            return;
          }

          if (hostElement.isEqualNode(this.currentHostElement)) {
            return;
          }

          if (this.host?.hasAttached()) {
            this.destroyPortal();
          }

          this.createPortal(hostElement);
          return;
        }

        if (!this.currentHostElement) {
          return;
        }

        if (this.host?.hasAttached()) {
          this.destroyPortal();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.destroyPortal();
  }

  private getHostElement(): Element | null {
    return document.querySelector(this.hostSelector);
  }

  private createPortal(host: Element): void {
    this.host = new DomPortalOutlet(host, undefined, undefined, undefined, document);

    const portal = new TemplatePortal(this.templateRef, this.vcr);
    this.host.attach(portal);
    this.currentHostElement = host;
  }

  private destroyPortal(): void {
    this.currentHostElement = null;
    this.host?.detach();
    this.host = undefined;
  }
}
