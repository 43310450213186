import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs/operators';

import { NavService } from '@smw/front-navigation';

import { AuthenticationService } from '../services';
import { CallbackUrlService } from '../services/callback-url.service';

export const loggedInGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const nav = inject(NavService);
  const authService = inject(AuthenticationService);
  const callbackUrlService = inject(CallbackUrlService);

  return authService.isAuthenticated().pipe(
    map((isAuthenticated) => {
      console.log(isAuthenticated);
      if (isAuthenticated) {
        return true;
      }

      callbackUrlService.register(state.url);
      return router.createUrlTree([nav.routeOf('auth')], {
        queryParamsHandling: 'merge',
        queryParams: route.queryParams,
      });
    }),
  );
};
