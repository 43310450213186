@if (isCollaborator()) {
  <a
    menuItem
    class="menu-item"
    [routerLink]="['collaborator' | route]"
    [routerLinkActive]="'active'"
    (click)="selectCollaboratorSpace()"
  >
    <smw-icon icon="collaborator"></smw-icon>
    <div>
      <div class="caption-sm">{{ 'sidebar_space-collaborator' | translate }}</div>
      <div class="body-sm company" [routerLinkActive]="'visible'">{{ company() }}</div>
    </div>
  </a>
}
@if (isManager()) {
  <a
    menuItem
    class="caption-sm menu-item"
    [routerLink]="['manager' | route]"
    [routerLinkActive]="'active'"
    (click)="changeSpace.emit('manager')"
  >
    <smw-icon icon="manager"></smw-icon>
    <div>
      <div class="caption-sm">{{ 'sidebar_space-manager' | translate }}</div>
      <div class="body-sm company" [routerLinkActive]="'visible'">{{ company() }}</div>
    </div>
  </a>
}
@if (isHr()) {
  <a
    menuItem
    class="caption-sm menu-item"
    [routerLink]="['hr' | route]"
    [routerLinkActive]="'active'"
    (click)="changeSpace.emit('hr')"
  >
    <smw-icon icon="hr"></smw-icon>
    <div>
      <div class="caption-sm">{{ 'sidebar_space-hr' | translate }}</div>
      <div class="body-sm company" [routerLinkActive]="'visible'">{{ company() }}</div>
    </div>
  </a>
}
@if (isFormateur()) {
  <a
    menuItem
    class="caption-sm menu-item"
    [routerLink]="['espace-formateur']"
    [routerLinkActive]="'active'"
    (click)="changeSpace.emit('formateur')"
  >
    <smw-icon icon="formation-outline"></smw-icon>
    <div>
      <div class="caption-sm">{{ 'sidebar_space-formateur' | translate }}</div>
      <div class="body-sm company" [routerLinkActive]="'visible'">{{ company() }}</div>
    </div>
  </a>
}
<a
  menuItem
  class="caption-sm menu-item"
  [routerLink]="['account' | route]"
  [routerLinkActive]="'active'"
  (click)="closeSidebar.emit()"
>
  <smw-icon icon="user"></smw-icon>
  <span>{{ 'my-information' | translate }}</span>
</a>
<button
  menuItem
  type="button"
  class="caption-sm menu-item logout"
  [title]="'logout' | translate"
  (click)="logout.emit()"
>
  <smw-icon icon="logout"></smw-icon>
  <span>{{ 'logout' | translate }}</span>
</button>
