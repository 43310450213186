<div class="email-input">
  <div class="tag-renderer" #emailListing>
    @for (email of this.emails(); track $index) {
      <span
        >{{ email }}
        <div (click)="removeEmail($index)"><smw-icon icon="close"></smw-icon></div
      ></span>
    }
  </div>
  <input
    type="text"
    (keydown)="onKeyDown($event)"
    formControl="email"
    [placeholder]="EffectivePlaceholder()"
    #emailInput
  />
</div>
