import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, switchMap, tap, throwError } from 'rxjs';

import { UserStore } from '@smw/front-stores';
import { User } from '@so-many-ways/models';
import { ActivationDto, RegisterDto } from '@so-many-ways/rest-dtos';

import { TokenStorageService } from './token-storage.service';

export type Registration = {
  email: string;
  firstname: string;
  lastname?: string;
  password: string;
  newsletterConsent: boolean;
  businessService?: string;
  jobResponsabilities?: string;
  client?: string | undefined;
};

export type Activation = {
  activationToken: string;
  password: string;
  newsletterConsent: boolean;
};

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private userStore: UserStore,
    private tokenStorageService: TokenStorageService,
  ) {}

  register(registration: Registration): Observable<User> {
    const payload: RegisterDto = registration;

    return this.http.post('/api/register', payload).pipe(
      catchError(() => {
        return throwError(() => new Error('Registration error'));
      }),
      switchMap(() => this.login(registration.email, registration.password)),
    );
  }

  activate(activation: Activation) {
    const payload: ActivationDto = activation;

    return this.http.post<{ access_token: string }>('/api/activate', payload).pipe(
      tap((data) => {
        this.tokenStorageService.store('authenticationToken', data.access_token);
      }),
      switchMap(() => this.userStore.loadUser()),
    );
  }

  login(email: string, password: string) {
    return this.http
      .post<{ access_token: string }>(`/api/login`, {
        email,
        password,
      })
      .pipe(
        tap((data) => {
          this.tokenStorageService.store('authenticationToken', data.access_token);
        }),
        switchMap(() => this.userStore.loadUser()),
      );
  }

  lostPassword(email: string) {
    return this.http.post<void>('/api/password/lost', { email });
  }

  resetPassword(password: string, passwordResetToken: string) {
    return this.http.post<void>('/api/password/reset', {
      token: passwordResetToken,
      password,
    });
  }

  logout(): Observable<void> {
    return new Observable((subscriber) => {
      this.tokenStorageService.delete('authenticationToken');
      this.userStore.clear();
      subscriber.next();
      subscriber.complete();
    });
  }

  isAuthenticated(): Observable<boolean> {
    console.log('is authenticated');
    return this.http.get<boolean>('/api/authenticated').pipe(
      tap((isauth) => console.log('is auth', isauth)),
      catchError((e) => {
        console.log('error', e)
        this.logout();
        return of(false);
      }),
    );
  }
}
