import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { BYPASS_AUTH } from './auth.context';
import { TokenStorageService } from '../services';

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const tokenStorage = inject(TokenStorageService);

  if (req.context.get(BYPASS_AUTH)) {
    return next(req);
  }

  const token = tokenStorage.get('sharingToken') ?? tokenStorage.get('authenticationToken');

  if (!token) {
    return next(req);
  }

  const request = req.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });

  return next(request);
};
