import { Routes } from '@angular/router';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

import { AppLayoutComponent } from '@smw/front-layout';
import { APP_ROUTES } from '@smw/front-navigation';
import { anonymousGuard, loggedInGuard } from '@so-many-ways/angular-authentication';

export const routes: Routes = [
  {
    path: APP_ROUTES.auth.base,
    canActivate: [anonymousGuard],
    loadChildren: () => import('@so-many-ways/angular-authentication').then((m) => m.routes),
  },
  { path: '', pathMatch: 'full', redirectTo: APP_ROUTES.collaborator.base },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [loggedInGuard],
    children: [
      {
        path: APP_ROUTES.account,
        data: { sidebar: true },
        loadComponent: () =>
          import('@so-many-ways/angular-account').then((m) => m.AccountPageComponent),
        title: marker('page-title-account'),
      },
      {
        path: APP_ROUTES.collaborator.base,
        loadChildren: () => import('@smw/front-spaces').then((m) => m.collaboratorRoutes),
      },
      {
        path: APP_ROUTES.manager.base,
        loadChildren: () => import('@smw/front-spaces').then((m) => m.managerRoutes),
      },
      {
        path: APP_ROUTES.hr.base,
        loadChildren: () => import('@smw/front-human-resources').then((m) => m.routes),
      },
      {
        path: APP_ROUTES.mobility.base,
        data: { checkUnavailability: true },
        loadChildren: () => import('@so-many-ways/angular-mobility').then((m) => m.routes),
      },
    ],
  },
  {
    path: APP_ROUTES.formations,
    canActivate: [loggedInGuard],
    loadChildren: () => import('@smw/front-formation').then((m) => m.routes),
  },
  {
    path: APP_ROUTES.formateurs.base,
    canActivate: [loggedInGuard],
    data: { sidebar: true },
    loadChildren: () => import('@smw/front-formateur').then((m) => m.routes),
  },
  {
    path: APP_ROUTES.fallback,
    loadComponent: () => import('@smw/front-404').then((m) => m.NotFoundComponent),
    title: marker('page-404-title'),
  },
];
