import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  input,
  output,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';

import { ButtonComponent } from '@smw/front-ui';

@Component({
  standalone: true,
  selector: 'smw-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
  imports: [ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements AfterViewInit {
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  onRoot = input(false);
  smallDevice = input(true);
  mediumDevice = input(false);
  largeDevice = input(false);

  openSidebar = output();

  @ViewChildren('content') private contentList!: QueryList<ElementRef<HTMLElement>>;

  ngAfterViewInit(): void {
    const hasContent = this.contentList.some((item) => !!item.nativeElement.children.length);
    if (hasContent) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'background-color', 'var(--white)');
    }
  }
}
