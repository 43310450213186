import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';

import { ButtonComponent } from '../button/button.component';
import { RainWComponent } from '../rain-w/rain-w.component';
import { SmwBackgroundComponent } from '../smw-background/smw-background.component';

@Component({
  selector: 'smw-success',
  standalone: true,
  templateUrl: './success-page.component.html',
  styleUrls: ['./success-page.component.less'],
  imports: [SmwBackgroundComponent, RainWComponent, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessPageComponent {
  title = input.required<string>();
  message = input<string>();
  cta = input.required<string>();

  confirm = output();
}
