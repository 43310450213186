import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { RoutePipe } from '@smw/front-navigation';
import { Space } from '@smw/front-stores';
import { IconComponent, MenuItemDirective } from '@smw/front-ui';
import { UserRole } from '@so-many-ways/models';

@Component({
  selector: 'smw-sidebar-menu',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    TranslateModule,
    IconComponent,
    RoutePipe,
    MenuItemDirective,
  ],
  templateUrl: 'menu.component.html',
  styleUrls: ['./menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarMenuComponent {
  company = input.required<string>();
  roles = input.required<UserRole[]>();

  logout = output();
  changeSpace = output<Space | 'formateur'>();
  closeSidebar = output();

  isCollaborator = computed(() => this.roles().includes('collaborator'));
  isManager = computed(() => this.roles().includes('manager'));
  isHr = computed(() => this.roles().includes('hr'));
  isFormateur = computed(() => this.roles().includes('formateur'));

  selectCollaboratorSpace(): void {
    this.changeSpace.emit('collaborator');
    this.closeSidebar.emit();
  }
}
